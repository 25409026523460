import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'shared/components/Button/Button';
import { BodyText } from '../ProjectQualification/ProjectQualification.styles';
import { ButtonWrapper } from './SystemInstallationContent.styles';

type Props = {
  displayChangeBillingAddress: boolean;
  onConfirmBillingAddress: () => void;
};

export function SystemInstallation({
  displayChangeBillingAddress,
  onConfirmBillingAddress,
}: Props) {
  const { t } = useTranslation();

  const body2List: string[] = t('systemInstallation.body2List', {
    returnObjects: true,
  });

  return (
    <div>
      <BodyText as="p">{t('systemInstallation.body1')}</BodyText>
      <ul>
        {Array.isArray(body2List)
          ? body2List.map((text) => (
              <li key={text}>
                <BodyText as="p">{text}</BodyText>
              </li>
            ))
          : null}
      </ul>
      <BodyText as="p" $margin="0 0 16px !important">
        {t('systemInstallation.body3')}
      </BodyText>
      {displayChangeBillingAddress && (
        <ButtonWrapper>
          <Button
            label={t('confirmBillingAddress.buttonTitle')}
            onClick={onConfirmBillingAddress}
          />
        </ButtonWrapper>
      )}
    </div>
  );
}

export default SystemInstallation;
