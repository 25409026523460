import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from './types';

export function LocationIcon({ color }: IconProps) {
  const theme = useTheme();
  const fillColor = color || theme.colors.iconIconPrimarySecondary1100;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M13.0285 6.01557C13.0285 3.2459 10.7852 1.00259 8.01557 1.00259C5.2459 1.00259 3.00259 3.2459 3.00259 6.01557C3.00259 6.50433 3.17178 7.17482 3.52269 8.00196C3.8642 8.81343 4.34356 9.69384 4.88873 10.5742C5.95712 12.3068 7.21976 13.9643 8.0187 14.9606C8.81451 13.9643 10.0803 12.3068 11.1487 10.5742C11.6907 9.69384 12.1732 8.8103 12.5147 8.00196C12.8594 7.17482 13.0285 6.50433 13.0285 6.01557ZM14.0311 6.01557C14.0311 8.7539 10.3654 13.629 8.75811 15.6405C8.37274 16.1198 7.65839 16.1198 7.27302 15.6405C5.66574 13.629 2 8.7539 2 6.01557C2 2.69447 4.69447 0 8.01557 0C11.3367 0 14.0311 2.69447 14.0311 6.01557ZM6.51168 6.01557C6.51168 6.41442 6.67012 6.79695 6.95216 7.07898C7.23419 7.36101 7.61671 7.51946 8.01557 7.51946C8.41442 7.51946 8.79695 7.36101 9.07898 7.07898C9.36101 6.79695 9.51946 6.41442 9.51946 6.01557C9.51946 5.61671 9.36101 5.23419 9.07898 4.95216C8.79695 4.67012 8.41442 4.51168 8.01557 4.51168C7.61671 4.51168 7.23419 4.67012 6.95216 4.95216C6.67012 5.23419 6.51168 5.61671 6.51168 6.01557ZM8.01557 8.52205C7.35081 8.52205 6.71327 8.25798 6.24321 7.78792C5.77316 7.31786 5.50908 6.68033 5.50908 6.01557C5.50908 5.35081 5.77316 4.71327 6.24321 4.24321C6.71327 3.77316 7.35081 3.50908 8.01557 3.50908C8.68033 3.50908 9.31786 3.77316 9.78792 4.24321C10.258 4.71327 10.5221 5.35081 10.5221 6.01557C10.5221 6.68033 10.258 7.31786 9.78792 7.78792C9.31786 8.25798 8.68033 8.52205 8.01557 8.52205Z"
        fill={fillColor}
      />
    </svg>
  );
}

export default LocationIcon;
