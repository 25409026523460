/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddressBodyType } from 'api/types';
import TextInput from 'shared/components/TextInput/TextInput';
import STATES from 'constants/states';
import { TDropdownDataItem } from 'shared/design-system/theme/dropdowns';
import Select from 'shared/components/Select/Select';
import { getDefaultStateOption } from 'modules/account/components/ContactForm/ContactForm';
import { StateZipGroup, StyledForm } from '../UserFormModalBody/UserFormBody.styles';

export const NEW_BILLING_FORM_MODAL_ID = 'new-billing-form-modal-id';

type Address = Omit<AddressBodyType, 'archived'>;

export interface IFormInput extends Address {}

type Props = {
  initialValues?: IFormInput;
  onSubmit: (formData: IFormInput) => void;
  placeholderEmpty?: boolean;
  marginTop?: boolean;
  borderRadius?: boolean;
  frontDropDown?: boolean;
};

export function BillingFormModalBody({
  initialValues,
  onSubmit,
  placeholderEmpty,
  marginTop,
  borderRadius,
  frontDropDown,
}: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.homeownerTransfer' });
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: initialValues,
  });
  const stateOptions: TDropdownDataItem[] = useMemo(
    () => STATES.map((state) => ({ title: state.text, value: state.value })),
    [],
  );

  const handleSelectOption = (value: string) => {
    setValue('state', value, { shouldDirty: true, shouldTouch: true });
    clearErrors('state');
  };

  return (
    <StyledForm id={NEW_BILLING_FORM_MODAL_ID} onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        id="contact-form-street1"
        ariaLabel={t('street1')}
        label={t('street1')}
        placeholder={placeholderEmpty ? ' ' : t('street1')}
        hasError={Boolean(errors.street_1)}
        errorMessage={errors.street_1?.message}
        {...register('street_1', { required: t('required') })}
      />
      <TextInput
        id="contact-form-street2"
        ariaLabel={t('street2')}
        label={t('street2')}
        placeholder={placeholderEmpty ? ' ' : t('street2')}
        hasError={Boolean(errors.street_2)}
        errorMessage={errors.street_2?.message}
        {...register('street_2')}
      />
      <TextInput
        id="contact-form-city"
        ariaLabel={t('city')}
        label={t('city')}
        placeholder={placeholderEmpty ? ' ' : t('city')}
        hasError={Boolean(errors.city)}
        errorMessage={errors.city?.message}
        {...register('city', { required: t('required') })}
      />

      <StateZipGroup>
        <Select
          id="contact-form-state"
          ariaLabelledBy={t('state')}
          label={t('state')}
          options={stateOptions}
          marginTop={marginTop}
          borderRadius={borderRadius}
          placeholder={placeholderEmpty ? '-- --' : 'Select an option'}
          hasError={Boolean(errors.state)}
          frontDropDown={frontDropDown}
          errorMessage={errors.state?.message}
          defaultOption={
            initialValues?.state
              ? getDefaultStateOption(initialValues.state, stateOptions)
              : undefined
          }
          onSelectOption={handleSelectOption}
          {...register('state', { required: t('required') })}
        />

        <TextInput
          id="contact-form-zip"
          ariaLabel={t('zip')}
          label={t('zip')}
          placeholder={placeholderEmpty ? ' ' : t('zip')}
          hasError={Boolean(errors.zip_code)}
          errorMessage={errors.zip_code?.message}
          {...register('zip_code', { required: t('required') })}
        />
      </StateZipGroup>
    </StyledForm>
  );
}

export default BillingFormModalBody;
