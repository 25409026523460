import styled, { css } from 'styled-components';

import { Text } from 'shared/components/Text/Text';
import { Link } from 'react-router-dom';

export const LoginFooter = styled.p`
  margin: 32px 0 32px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 200;
  color: ${({ theme }) => theme.colors.textSecondarySubtleGrey80};
`;

export const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.colors.textSystemInfoSecondary2100};
  font-weight: 600;
`;

export const AuthBox = styled.div`
  ${({ theme }) => css`
    margin: 32px auto 0 auto;
    background: ${theme.colors.cardElevationMidWhite};
    border: 1px solid ${theme.colors.strokeSecondaryGrey10};
    box-shadow: ${theme.elevations.high};
    color: ${theme.colors.textPrimarySecondary1100};
    border-radius: 12px;
    padding: 16px;
    max-width: 409px;

    @media screen and (min-width: ${theme.breakpoints.small}) {
      border-radius: 18px;
      padding: 24px 35px;
    }

    @media screen and (min-width: ${theme.breakpoints.medium}) {
      border-radius: 24px;
      max-width: 498px;
      padding: 48px 78px;
    }
  `}
`;

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyText = styled(Text)`
  font-family: Avenir;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;
