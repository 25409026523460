import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'shared/components/Toast/Toast';
import { useTheme } from 'styled-components';

import { getUsers, useInviteUser, useUpdateUser } from 'api/user';
import { UserType } from 'api/types';
import { isHomeowner } from 'shared/utils/checkUserRole';
import { AdminContext } from 'contexts/AdminContext';
import { Header } from 'shared/components/Header/Header';
import { Button } from 'shared/components/Button/Button';
import { Plus } from 'shared/components/icons';
import { AdminsTable } from '../components/AdminsTable/AdminsTable';
import { AdminsBody, AdminsWapper } from './Admins.styles';
import {
  AddUserModal,
  IFormInput as AddUserFormInput,
} from '../components/AddUserModal/AddUserModal';
import {
  EditRoleModal,
  IFormInput as EditRoleFormInput,
} from '../components/EditRoleModal/EditRoleModal';

enum ModalType {
  ADD_USER,
  EDIT_ROLE,
}
export function Admins() {
  const [openModal, setOpenModal] = useState<ModalType | null>(null);
  const [userToEdit, setUserToEdit] = useState<UserType | null>(null);

  const { internalUsers, setInternalUsers } = useContext(AdminContext);

  const { t } = useTranslation();
  const theme = useTheme();

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const handleOpenModal = (modal: ModalType) => {
    setOpenModal(modal);
  };

  const handleClickEditRole = (user: UserType) => {
    setUserToEdit(user);
    handleOpenModal(ModalType.EDIT_ROLE);
  };

  const { mutate: inviteUser } = useInviteUser({
    onSuccess: (res) => {
      setInternalUsers([...internalUsers, res]);

      toast({
        type: 'success',
        title: t('toast.success'),
        message: 'User successfully created',
        theme,
      });

      handleCloseModal();
    },
    onError: (error) => {
      const { response, message: axiosMessage } = error;
      const responseData = response?.data;
      let errorMessage: string | undefined;

      if (Array.isArray(responseData)) {
        errorMessage = responseData[0] as string;
      } else if (typeof responseData === 'string') {
        errorMessage = responseData;
      } else if (responseData?.detail) {
        errorMessage = Array.isArray(responseData.detail)
          ? responseData.detail[0]
          : responseData.detail;
      }

      toast({
        type: 'error',
        title: t('toast.error'),
        message: errorMessage || axiosMessage,
        theme,
      });
    },
  });

  const { mutate: updateUser } = useUpdateUser({
    onSuccess: (res) => {
      getUsers({
        internal_users: true,
        page_size: 100,
      }).then((response) => {
        setInternalUsers(response.data);
      });

      toast({
        type: 'success',
        title: t('toast.success'),
        message: `Successfully updated role for ${res.email} from ${userToEdit?.role} to ${res.role}.`,
        theme,
      });

      handleCloseModal();
    },
    onError: (_, variables) => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: `Only Super Admins can change a user's role to ${variables.body.role}.`,
        theme,
      });
    },
  });

  useEffect(() => {
    getUsers({
      internal_users: true,
      page_size: 100,
    }).then((response) => {
      setInternalUsers(response.data);
    });
  }, [setInternalUsers]);

  const handleSubmitUserForm = (formData: AddUserFormInput) => {
    const { address, ...userWithoutAddress } = formData;

    if (isHomeowner(userWithoutAddress.role)) {
      inviteUser({ ...userWithoutAddress, address });
    } else {
      inviteUser(userWithoutAddress);
    }
  };

  const handleSubmitEditRoleForm = (formData: EditRoleFormInput) => {
    if (userToEdit) {
      updateUser({ user_id: userToEdit.id, body: { role: formData.role } });
    } else {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: 'Something went wrong',
        theme,
      });
    }
  };

  return (
    <>
      <AdminsWapper>
        <Header pageName="AGREEMENTS" title={t('admin.admins')} />

        <AdminsBody>
          <div>
            <Button
              label="Add User"
              Icon={Plus}
              onClick={() => handleOpenModal(ModalType.ADD_USER)}
            />
          </div>
          <AdminsTable internalUsers={internalUsers} onClickEditRole={handleClickEditRole} />
        </AdminsBody>
      </AdminsWapper>
      <AddUserModal
        isOpen={openModal === ModalType.ADD_USER}
        onSubmitUserForm={handleSubmitUserForm}
        onClose={handleCloseModal}
      />
      {userToEdit && (
        <EditRoleModal
          isOpen={openModal === ModalType.EDIT_ROLE}
          initialRole={userToEdit.role}
          onSubmitUserForm={handleSubmitEditRoleForm}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}

export default Admins;
