import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { getEnv } from 'shared/utils/getEnv';
import { getEnvBool } from 'shared/utils/getEnvBool';
import { getEnvInt } from 'shared/utils/getEnvInt';
import { guessEnvironment } from 'shared/utils/guessEnvironment';

const initializeDatadog = () => {
  if (import.meta.env.VITE_APP_VERSION !== 'local') {
    datadogLogs.init({
      clientToken: getEnv('VITE_APP_DATADOG_CLIENT_TOKEN'),
      env: guessEnvironment(),
      forwardErrorsToLogs: getEnvBool('VITE_APP_DATADOG_FORWARD_ERRORS_TO_LOGS'),
      sampleRate: getEnvInt('VITE_APP_DATADOG_SAMPLE_RATE'),
      service: getEnv('VITE_APP_DATADOG_SERVICE_NAME'),
      site: getEnv('VITE_APP_DATADOG_SITE'),
      version: getEnv('VITE_APP_VERSION'),
    });

    datadogRum.init({
      applicationId: getEnv('VITE_APP_DATADOG_RUM_APP_ID'),
      clientToken: getEnv('VITE_APP_DATADOG_RUM_CLIENT_TOKEN'),
      defaultPrivacyLevel: 'mask-user-input',
      env: guessEnvironment(),
      replaySampleRate: 0,
      service: getEnv('VITE_APP_DATADOG_SERVICE_NAME'),
      sessionReplaySampleRate: 0,
      sessionSampleRate: 100,
      site: getEnv('VITE_APP_DATADOG_SITE'),
      trackFrustrations: true,
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      version: getEnv('VITE_APP_VERSION'),
      allowedTracingUrls: [/https:\/\/(dev\.|qa\.)?myeverbright\.com/],
    });

    datadogRum.startSessionReplayRecording();
  }
};

export default initializeDatadog;
