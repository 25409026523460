import axios, { AxiosError } from 'axios';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useMutation, UseMutationResult } from 'react-query';

import { routes } from 'routes';
import { AddressBodyType, UserFormType, UserType } from 'api/types';

export type UpdateAccountRequestBody = {
  address?: AddressBodyType;
  archived?: boolean;
};

export function updateAccount(account_id: string, body: UpdateAccountRequestBody) {
  return axios.patch(routes.account(account_id), { ...body });
}

export function checkClaimToken(token: string) {
  return axios.post(routes.claimToken, { token });
}

export function sendAdminClaimAccountEmail(user_id: string) {
  return axios.post(routes.sendClaimAccountEmail, { user_id });
}

export function sendHomeownerClaimAccountEmail(email: string, is_resend: boolean) {
  return axios.post(routes.sendClaimAccountEmail, { email, is_resend });
}

export function inviteHomeowner(user: UserFormType) {
  return axios.post(routes.invite, user);
}

export function inviteAdmin(user: UserFormType) {
  return axios.post(routes.invite, { ...user, is_admin_invite: true });
}

export function sendResetPasswordLink(email: string): Promise<undefined> {
  return axios.post(routes.forgotPassword, { email });
}

export function submitCreatePassword({
  password,
  token,
}: {
  password: string;
  token: string;
}): Promise<undefined> {
  return axios.post(routes.resetPassword, { token, password });
}

type UseCheckClaimTokenBody = {
  onSuccess?: (data: { email: string }) => void;
  onError?: (error: AxiosError) => void;
};

export const useCheckClaimToken = ({
  onSuccess,
  onError,
}: UseCheckClaimTokenBody): UseMutationResult<{ email: string }, unknown, string> =>
  useMutation((token: string) => checkClaimToken(token).then((res) => res.data), {
    onSuccess,
    onError,
  });

type UseClaimAccountBody = {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
};

export const useClaimAccount = ({
  onSuccess,
  onError,
}: UseClaimAccountBody): UseMutationResult<unknown, unknown, { token: string; password: string }> =>
  useMutation(
    (body: { token: string; password: string }) => axios.post(routes.claimUserAccount, body),
    {
      onSuccess,
      onError,
    },
  );

export const useSetPasswordWithAccessCode = ({
  onSuccess,
  onError,
}: UseClaimAccountBody): UseMutationResult<
  unknown,
  unknown,
  { email: string; password: string; access_code: string }
> =>
  useMutation(
    (body: { email: string; password: string; access_code: string }) =>
      axios.post(routes.setPasswordWithAccessCode, body),
    {
      onSuccess,
      onError,
    },
  );

type ErrorResponse = {
  detail?: string | string[];
  default_code?: string;
  error_codes?: string;
};

type UseTransferAccountBody = {
  onSuccess?: (data: TransferAccountResponse) => void;
  onError?: (error: AxiosError<ErrorResponse>) => void;
};

interface TransferAccountParams {
  account_id: string;
  body: { new_users: UserFormType[] };
}

interface TransferAccountResponse {
  users: UserType[];
}

export const useTransferAccount = ({
  onSuccess,
  onError,
}: UseTransferAccountBody): UseMutationResult<
  TransferAccountResponse,
  AxiosError,
  TransferAccountParams
> =>
  useMutation(
    ({ account_id, body }) => {
      const { new_users: newUsersBody } = body;

      const newUsers = newUsersBody.map((user) => {
        const { phone_number: phoneNumber, ...userRest } = user;

        return {
          ...userRest,
          phone_number: parsePhoneNumberFromString(phoneNumber, 'US')?.format('E.164'),
        };
      });

      return axios
        .post(routes.transferAccounts(account_id), { new_users: newUsers })
        .then((res) => res.data);
    },
    {
      onSuccess,
      onError,
    },
  );
