import { getEnv } from './getEnv';

export function getEnvBool(key: string) {
  const val = getEnv(key);
  if (val === 'true') {
    return true;
  }
  if (val === 'false') {
    return false;
  }
  throw new Error(`${key} is not "true" or "false"`);
}

export default getEnvBool;
