import styled from 'styled-components';

export const BillingAddressModalContent = styled.div`
  ${({ theme }) => `
        display: flex-column;
        align-items: center;
        width: 416px;
        height: fit-content;
        padding-bottom: 36px;
        @media screen and (max-width: ${theme.breakpoints.small}) {
            width: 100%;
        }
    `}
`;

export const StyledText = styled.p`
  ${({ theme }) => `
  color: ${theme.colors.textSecondaryGrey90};
  font-family: Avenir;
  font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0px;
    margin-top: 0px;
    strong {
    font-weight: 700;
    }
`}
`;

export const StyledLocation = styled.p`
  ${({ theme }) => `
  color: ${theme.colors.textSecondaryGrey90};
  font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 8px;
`}
`;

export const StyledLocationBottom = styled.p`
  ${({ theme }) => `
  color: ${theme.colors.textSecondaryGrey90};
  font-family: Avenir;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0px;
    margin-top: 4px;
    margin-left: 24px;
`}
`;

export const LocationDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

export const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export default {};
