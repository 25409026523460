import React from 'react';
import { useTheme } from 'styled-components';
import { IconProps } from './types';

export function AngleIcon({ color }: IconProps) {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.10249 13.7451L4.22372 8.56288C4.07456 8.4052 4 8.20286 4 8.00034C4 7.79789 4.0746 7.59531 4.22372 7.4378L9.10249 2.25559C9.4136 1.92744 9.93131 1.91364 10.2593 2.22363C10.5896 2.53473 10.6012 3.05483 10.2912 3.38076L5.91365 8.00043L10.2912 12.6201C10.6013 12.9461 10.5896 13.4639 10.2593 13.7772C9.93437 14.086 9.41615 14.0724 9.10249 13.7451Z"
        fill={color || theme.colors.textPrimarySecondary1100}
      />
    </svg>
  );
}

export default AngleIcon;
