import React from 'react';

import { Table } from 'shared/components/Table/Table';
import { TableCellString } from 'shared/components/Table/TableCell/TableCellString/TableCellString';
import { SentEmailType } from 'modules/types/util';

type Props = {
  emailList: SentEmailType[];
};

export function EmailsTable({ emailList }: Props) {
  const columns = [{ title: 'Date' }, { title: 'Email Description' }, { title: 'Destination' }];

  const rows = emailList.map((email) => {
    const date = new Date(email.date_sent);

    return {
      key: `table-${email.id}`,
      cells: [
        <TableCellString text={date.toLocaleString('en-US')} />,
        email.template_description ? (
          <span>
            {email.template_description}
            <br /> {email.email_template}
          </span>
        ) : (
          <TableCellString text={email.email_template} />
        ),
        <TableCellString text={email.user_email} />,
      ],
    };
  });

  return <Table columns={columns} rows={rows} isFullWidth />;
}

export default EmailsTable;
