import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { TModalStyleVariantsKey } from 'shared/design-system/theme/modals';
import { LoadingLogo } from './LoadingLogo';
import { CtaButton, CtaButtons } from './types';
import {
  ContainerClose,
  Header,
  Content,
  Footer,
  modalStyles,
  PageFormat,
  SubHeader,
  ContentWrapper,
  InnerContainer,
  ButtonWrapper,
  HeaderAndContent,
} from './Modal.styles';
import { Button } from '../Button/Button';
import { handleChatDisplay } from './handleChatDisplay.util';
import { CloseIcon } from '../icons';

type ModalProps = {
  children?: React.ReactNode;
  dataTestId?: string;
  contentLabel: string;
  isOpen: boolean;
  title?: string;
  ctaButtons?: CtaButtons;
  ctaSpacedBetween?: boolean;
  noCtaPadding?: boolean;
  showCloseButton?: boolean;
  closeTimeout?: number;
  isFullWidth?: boolean;
  isFullHeight?: boolean;
  fitContent?: boolean;
  isLoading?: boolean;
  padding?: string;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  onRequestClose?: () => void;
  styleVariant?: TModalStyleVariantsKey;
  activePage?: number;
  totalPages?: number;
  subHeader?: React.ReactNode;
  hasElevationEffect?: boolean;
};

/**
 * @param {React.ReactNode} [children] - children inside of modal (optional)
 * @param {string} [dataTestId] - string id used for testing (optional)
 * @param {string} [contentLabel] - string indicating how the content container should be announced to screenreaders
 * @param {boolean} [isOpen] - boolean describing if the modal should be shown or not
 * @param {string} [title] - title to display in the header (optional)
 * @param {CtaButtons} [ctaButtons] - cta button object. The order of the primary/secondary buttons in the UI is the order in the json (optional)
 * @param {boolean} [ctaSpacedBetween] - boolean to space buttons in between; default false; buttons will be right aligned (optional)
 * @param {boolean} [showCloseButton] - allow a component to hide the close button if it will be handled differently (optional)
 * @param {number} [closeTimeout] - number indicating the milliseconds to wait before closing the modal; default is 0 (optional)
 * @param {boolean} [isFullWidth] - determine the modal width; default: false (optional)
 * @param {boolean} [isFullHeight] - determine the modal height; default: false (optional)
 * @param {string} [padding] - optionally set overall modal padding with a single value; default: undefined (optional)
 * @param {boolean} [isLoading] - determine whether modal should show a loading logo; default: false (optional)
 * @param {boolean} [shouldCloseOnOverlayClick] - indicating if the overlay should close the modal; default: true (optional)
 * @param {boolean} [shouldCloseOnEsc] - indicating if pressing the esc key should close the modal; default: true; Note: By disabling the esc key from closing the modal you may introduce an accessibility issue. (optional)
 * @param {function} [onAfterOpen] - function that will be run after the modal has opened (optional)
 * @param {function} [onAfterClose] - function that will be run after the modal has closed. (optional)
 * @param {function} [onRequestClose] - function that will be run when the modal is requested to be closed (either by clicking on overlay or pressing ESC). Note: It is not called if isOpen is changed by other means. (optional)
 * @param {TModalStyleVariantsKey} [styleVariant] - property that takes in primary/secondary and will default to primary (optional)
 * @param {number} [activePage] - number indicating the number of the active page in the modal (optional)
 * @param {number} [totalPages] - number indicating the number of total pages in the modal (optional)
 * @param {React.ReactNode} [subHeader] - subheader under the larger title (optional)
 * @param {boolean} [hasElevationEffect] - shows the body as inset if its content is overflowing (optional)
 * @param {boolean} [noCtaPadding] - removes padding from interior of button
 */

export function Modal({
  children,
  dataTestId,
  isOpen,
  isFullWidth = false,
  isFullHeight = false,
  fitContent = false,
  isLoading = false,
  padding,
  title,
  subHeader,
  ctaButtons,
  ctaSpacedBetween = false,
  showCloseButton = true,
  noCtaPadding = false,
  contentLabel,
  closeTimeout = 0,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  onAfterOpen,
  onAfterClose,
  onRequestClose,
  styleVariant = 'primary',
  activePage,
  totalPages,
  hasElevationEffect = false,
}: ModalProps) {
  const [showFooterElevationEffect, setShowFooterElevationEffect] = useState(hasElevationEffect);
  const [showHeaderElevationEffect, setShowHeaderElevationEffect] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const theme = useTheme();

  const styles = modalStyles(theme);
  const showPageIndicator = activePage && totalPages && activePage <= totalPages;

  const handleAfterOpen = () => {
    document.body.style.overflow = 'hidden'; // lock scrolling of the body element.
    onAfterOpen?.();
  };

  const handleAfterClose = () => {
    document.body.style.overflow = 'auto'; // re-enables scrolling of the body element.
    onAfterClose?.();
  };

  const handleRequestClose = () => {
    onRequestClose?.();
  };

  const hasScrollableContent = useMemo(
    () => showHeaderElevationEffect || showFooterElevationEffect,
    [showHeaderElevationEffect, showFooterElevationEffect],
  );

  useEffect(() => {
    if (isOpen) {
      handleChatDisplay('none');
    } else {
      handleChatDisplay('unset');
    }
  }, [isOpen]);

  const handleOverflowContent = useCallback(() => {
    const content = contentRef.current;

    const isContentOverflowingNow =
      (content?.clientHeight as number) + (content?.scrollTop as number) <
      (content?.scrollHeight as number);
    if (isContentOverflowingNow !== showFooterElevationEffect) {
      setShowFooterElevationEffect(isContentOverflowingNow);
    }
  }, [showFooterElevationEffect]);

  useEffect(() => handleOverflowContent(), [children, handleOverflowContent]);

  const handleScroll = () => {
    handleOverflowContent();
    const scrollTop = contentRef.current?.scrollTop;
    if (scrollTop) {
      if (scrollTop > 10 && !showHeaderElevationEffect) {
        setShowHeaderElevationEffect(true);
      }
      if (scrollTop < 10 && showHeaderElevationEffect) {
        setShowFooterElevationEffect(false);
      }
    } else if (showHeaderElevationEffect) {
      setShowHeaderElevationEffect(false);
    }
  };

  const ctaButtonsUI = ctaButtons && (
    <ButtonWrapper $noCtaPadding={noCtaPadding}>
      {Object.keys(ctaButtons).map((key) => {
        const {
          label,
          onClick,
          type,
          isFullWidth: isFullWidthBtn,
          isLoading: isButtonLoading,
          formId,
          Icon,
          margin,
          iconCenterAlign,
          iconBorderAlign,
        } = (ctaButtons[key as keyof CtaButtons] as CtaButton) || {};

        return label ? (
          <Button
            key={`modal-cta-button-${key}`}
            dataTestId={`cta-button-${key}`}
            form={formId}
            onClick={onClick}
            isFullWidth={isFullWidthBtn}
            type={type}
            Icon={Icon}
            margin={margin}
            label={label}
            iconCenterAlign={iconCenterAlign}
            iconBorderAlign={iconBorderAlign}
            styleVariant={key as TModalStyleVariantsKey}
            isLoading={isButtonLoading}
          />
        ) : null;
      })}
    </ButtonWrapper>
  );

  return (
    <ReactModal
      contentLabel={contentLabel}
      testId={dataTestId}
      style={styles}
      isOpen={isOpen}
      preventScroll={false}
      closeTimeoutMS={closeTimeout}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      onRequestClose={handleRequestClose}
      appElement={document.querySelector('#root') as HTMLElement}
    >
      <InnerContainer
        $isFullWidth={isFullWidth}
        $isFullHeight={isFullHeight}
        $styleVariant={styleVariant}
        $fitContent={fitContent}
        $padding={padding}
      >
        <HeaderAndContent>
          <Header
            $showButton={showCloseButton}
            $styleVariant={styleVariant}
            $hasSubheader={Boolean(subHeader)}
            $hasElevation={showHeaderElevationEffect}
          >
            <ContainerClose
              aria-label="Close"
              $showButton={showCloseButton}
              onClick={handleRequestClose}
              $styleVariant={styleVariant}
            >
              <CloseIcon
                size={styleVariant === 'tertiary' ? 'large' : 'small'}
                color={
                  styleVariant === 'tertiary'
                    ? theme.colors.textPrimarySecondary1100
                    : theme.colors.iconIconInverseWhite
                }
              />
            </ContainerClose>
            <h3>{title}</h3>
            {subHeader && <SubHeader>{subHeader}</SubHeader>}
          </Header>
          <ContentWrapper
            ref={contentRef}
            $hasScrollbar={hasScrollableContent}
            onScroll={handleScroll}
          >
            <Content $styleVariant={styleVariant} $hasScrollbar={hasScrollableContent}>
              {children}
            </Content>
          </ContentWrapper>
        </HeaderAndContent>

        <Footer
          $ctaSpacedBetween={ctaSpacedBetween}
          $hasElevation={showFooterElevationEffect}
          $hasContent={Boolean(showPageIndicator) || Boolean(ctaButtonsUI)}
        >
          {showPageIndicator && (
            <PageFormat>{t('modal.pageIndicator', { activePage, totalPages })}</PageFormat>
          )}
          {ctaButtonsUI}
        </Footer>
      </InnerContainer>
      {isLoading && <LoadingLogo />}
    </ReactModal>
  );
}

export default {};
