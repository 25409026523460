import React from 'react';

import { AgreementStatsType } from 'api/types';
import { FieldCountTable } from '../FieldCountTable/FieldCountTable';
import { AgreementStatisticsWrapper, FlexColumn } from './AgreementStatistics.styles';

type Props = {
  stats: AgreementStatsType;
};

export function AgreementStatistics({ stats }: Props) {
  return (
    <AgreementStatisticsWrapper>
      <FlexColumn>
        <FieldCountTable name="Current Milestones" fields={stats.current_milestones} />
        <FieldCountTable
          name="Welcome Checklist Results"
          fields={stats.welcome_checklist_results}
        />
        <FieldCountTable name="Contract Types" fields={stats.contract_types} />
        <FieldCountTable name="Product Types" fields={stats.product_types} />
      </FlexColumn>

      <FieldCountTable name="Product Names" fields={stats.product_names} />
      <FieldCountTable name="States" fields={stats.states} />
    </AgreementStatisticsWrapper>
  );
}

export default AgreementStatistics;
