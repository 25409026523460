import React from 'react';

import { useGetAgreementStatistics } from 'api/agreement';
import { useGetUserStatistics } from 'api/user';

import { UserStatistics } from '../components/UserStatistics/UserStatistics';
import { AgreementStatistics } from '../components/AgreementStatistics/AgreementStatistics';

export function Statistics() {
  const { data: agreementStats } = useGetAgreementStatistics();

  const { data: userStats } = useGetUserStatistics();

  return (
    <div>
      {userStats && <UserStatistics stats={userStats} />}

      {agreementStats && <AgreementStatistics stats={agreementStats} />}
    </div>
  );
}

export default Statistics;
