import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

import useUser from 'hooks/useUser';
import pages from 'pages';
import { LoadingLogo } from 'shared/components/Modal/LoadingLogo';
import { isSupport } from '../shared/utils/checkUserRole';

type Props = {
  children?: JSX.Element;
};
export function SupportGuard({ children }: Props) {
  const { userResult } = useUser();
  const location = useLocation();

  if (userResult?.loading) return <LoadingLogo />;

  const isAllowed = isSupport(userResult?.user?.role);

  if (!isAllowed) {
    return <Navigate to={pages.MANAGE_FAQS} replace state={{ path: location.pathname }} />;
  }

  return children || <Outlet />;
}

export default SupportGuard;
