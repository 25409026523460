import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import { useGetFAQs } from 'api/faqs';
import { useUser } from 'hooks/useUser';
import { filterFAQs } from 'modules/overview/utils/filterFAQs';
import { AccordionItemType } from 'shared/components/Accordion/Accordion';
import { Header } from 'shared/components/Header/Header';
import { Body, SupportWrapper } from './Support.styles';
import { SupportForm } from '../components/SupportForm/SupportForm';
import { FAQs } from '../components/FAQs/FAQs';

export function Support() {
  const { t } = useTranslation();
  const { userResult } = useUser();

  const [searchQuery, setSearchQuery] = useState('');

  const { data: faqData } = useGetFAQs();

  const filteredFAQs = useMemo(
    () => filterFAQs(faqData || null, userResult?.user, searchQuery),
    [faqData, userResult?.user, searchQuery],
  );

  const items = useMemo((): AccordionItemType[] => {
    if (filteredFAQs)
      return filteredFAQs.map((faq) => ({
        label: faq.question,
        id: faq.id,
        content: <p>{faq.answer}</p>,
      }));
    return [];
  }, [filteredFAQs]);

  const debouncedSetQuery = debounce((q) => setSearchQuery(q), 300);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetQuery(e.target.value);
  };
  return (
    <SupportWrapper>
      <Header pageName="SUPPORT" title={t('support&FAQs.support&FAQs')} />

      <Body>
        {userResult?.user?.email && <SupportForm email={userResult.user.email} />}

        <FAQs searchQuery={searchQuery} items={items} onChange={handleChange} />
      </Body>
    </SupportWrapper>
  );
}
export default Support;
