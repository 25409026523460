import styled from 'styled-components';

export const MobileAppSpacer = styled.div`
  height: 76px;
`;

export const MobileAppBannerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #eaeaea;
  padding: 0 10px;
  z-index: 999;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

export const MobileAppBannerContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  column-gap: 5px;
  text-align: left;
  width: 100%;
`;

export const BannerIconButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
`;

export const BannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  b {
    font-size: 14px;
  }

  p {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.textPrimarySecondary1100};
  }
`;
