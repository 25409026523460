import React from 'react';

import { UserStatsType } from 'api/types';
import { Text } from 'shared/components/Text/Text';
import { AccountChart } from '../AccountChart/AccountChart';
import { Header, UserStatisticsWrapper } from './UserStatistics.styles';

type Props = {
  stats: UserStatsType;
};

export function UserStatistics({ stats }: Props) {
  const {
    total_homeowners_invited: homeownersInvited,
    total_homeowner_accounts_claimed: homeownerAccountsClaimed,
    claimed_offset: claimedOffset,
    invited: invitedSeriesData,
    claimed: claimedSeriesData,
  } = stats;
  const unclaimedAccounts = homeownersInvited - homeownerAccountsClaimed;

  return (
    <UserStatisticsWrapper>
      <Header>
        <div>
          <Text as="h3">{homeownersInvited}</Text>
          <Text as="p">Homeowners Invited</Text>
        </div>

        <div>
          <Text as="h3">{homeownerAccountsClaimed}</Text>
          <Text as="p">Claimed Homeowner Accounts</Text>
        </div>

        <div>
          <Text as="h3">{unclaimedAccounts}</Text>
          <Text as="p">Unclaimed Homeowner Accounts</Text>
        </div>
      </Header>

      <AccountChart
        claimedOffset={claimedOffset}
        claimedSeriesData={claimedSeriesData}
        invitedSeriesData={invitedSeriesData}
      />
    </UserStatisticsWrapper>
  );
}

export default UserStatistics;
