import styled from 'styled-components';

export const UserStatisticsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
