import {
  TDropdownStyleVariantsKey,
  TDropdownStatesKey,
  getDropdownStyleVariantValues,
} from 'shared/design-system/theme/dropdowns';
import styled, { css } from 'styled-components';

export const CustomSelectContainer = styled.div`
  cursor: pointer;
  position: relative;
`;

export const NativeSelect = styled.select`
  display: none;
`;

type SelectSelectedProps = {
  $styleVariant: TDropdownStyleVariantsKey;
  $state: TDropdownStatesKey;
  $borderRadius?: boolean;
  $marginTop?: boolean;
};

export const SelectScrim = styled.div<{ $show: boolean }>`
  ${({ theme, $show }) => `
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: ${theme.colors.backgroundOpacityOpacityScrimGrey8075};
        display: block;
        display: ${$show ? 'block' : 'none'};
        @media (min-width: ${theme.breakpoints.small}) {
            display: none;
        }
    `}
`;

export const SelectSelected = styled.div<SelectSelectedProps>`
  ${({ $styleVariant, $state, theme, $borderRadius, $marginTop }) => {
    const { background, border, borderRadius } = getDropdownStyleVariantValues(
      theme,
      $styleVariant,
      $state,
    );

    return css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: ${$marginTop ? '2px' : '0px'};
      padding: 8px 12px;
      background: ${background};
      border: ${border};
      border-radius: ${$borderRadius ? '4px' : borderRadius};
      &:focus,
      &:focus-visible {
        outline: none;
        box-shadow:
          0px 0px 0px 1px #fff,
          0px 0px 0px 4px ${theme.colors.cardInteractionFocusBlue};
        z-index: 1;
      }
    `;
  }}
`;

type InputHintProps = {
  $styleVariant: TDropdownStyleVariantsKey;
  $inputState: TDropdownStatesKey;
  $showHint: boolean;
};
export const InputHint = styled.div<InputHintProps>`
  ${({ $inputState, $styleVariant, $showHint, theme }) => {
    const { hintFontColor } = getDropdownStyleVariantValues(theme, $styleVariant, $inputState);

    return css`
      display: ${$showHint ? 'flex' : 'none'};
      color: ${hintFontColor};
      font-family: Avenir;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      padding-top: 4px;
      align-items: center;
      gap: 8px;
    `;
  }}
`;
