import styled, { css } from 'styled-components';
import {
  TAccordionStyleVariantsKey,
  TERTIARY_ACCORDION_STYLE_VARIANT,
} from 'theme/accordions/TAccordions';
import { accordionThemeColors } from 'shared/design-system/theme/accordion';
import { FONT_FAMILY, Text } from '../Text/Text';

export const AccordionStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
  // prevent elevation effect being cut off for accordion items
  padding: 0 4px;
`;

type AccordionItemStyledProps = {
  $styleVariant: TAccordionStyleVariantsKey;
  $size: 'large' | 'small';
  $isExpanded: boolean;
};

export const AccordionItemStyled = styled.div<AccordionItemStyledProps>`
  ${({ $styleVariant, $size, $isExpanded, theme }) => {
    let marginBottom = $size === 'large' ? '24px' : '20px';
    if ($styleVariant === TERTIARY_ACCORDION_STYLE_VARIANT) marginBottom = '32px';
    return css`
      background-color: transparent;
      border-radius: 8px;
      margin-bottom: ${marginBottom};
      overflow: hidden;
      border: 1px solid ${theme.colors.strokeSecondaryGrey10};
      ${$isExpanded
        ? `
      box-shadow: ${theme.elevations.high};
      `
        : `
      box-shadow: ${theme.elevations.mid};
      `}

      @media screen and (min-width: ${theme.breakpoints.large}) {
        margin-bottom: 32px;
      }
    `;
  }}
`;

type AccordionItemButtonProps = {
  $isExpanded: boolean;
  $styleVariant: TAccordionStyleVariantsKey;
  $size: 'large' | 'small';
};

export const AccordionItemButton = styled.button<AccordionItemButtonProps>`
  ${({ $styleVariant, $size, theme }) => {
    const backgroundColorKey = accordionThemeColors[$styleVariant].background;
    const background = theme.colors[backgroundColorKey];
    let padding = $size === 'large' ? '12px 20px' : '9px 16px';

    if ($styleVariant === 'tertiary') {
      padding = $size === 'large' ? '16px 24px' : '12px 20px';
    }

    return css`
      width: 100%;
      color: #fff;
      outline-color: #fff;
      border: 0;
      cursor: pointer;
      background-color: ${background};
      padding: ${padding};
      svg {
        margin-left: auto;
      }

      &:hover {
        background: ${theme.colors.cardInteractionHoverGrey05};
      }
    `;
  }}
`;

type AccordionItemContentProps = {
  $isExpanded: boolean;
  $bgColor?: string;
  $size?: 'large' | 'small';
  $styleVariant: TAccordionStyleVariantsKey;
};

export const AccordionItemContent = styled.div<AccordionItemContentProps>`
  ${({ $isExpanded, $bgColor, $size = 'large', $styleVariant, theme }) => {
    let padding = $size === 'small' ? '16px' : '4px 20px 20px 20px';

    if ($styleVariant === 'tertiary') padding = '0 24px 20px';

    return `
        display: flex;
        font-family: ${FONT_FAMILY};
        font-size: 16px;
        font-weight: 100;
        line-height: 1.5;
        max-height: ${
          $isExpanded ? '99999px' : '0px'
        }; // we have to give it some height for the css to work.
        opacity: ${$isExpanded ? 1 : 0};
        box-sizing: border-box;
        overflow: ${$isExpanded ? 'visible' : 'hidden'};
        visibility: ${$isExpanded ? 'visible' : 'hidden'};
        background-color: ${$bgColor ?? 'inherit'};
        color: ${theme.colors.textSecondaryGrey90};
        list-style: none;
        margin: 0;
        padding: ${$isExpanded ? padding : 0};
        overflow-y: visible;

        p {
          margin: 0;
          color: ${theme.colors.textSecondaryGrey90};
        }
      `;
  }}
`;

export const LabelIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Label = styled(Text)<{ $styleVariant: TAccordionStyleVariantsKey }>`
  ${({ theme, $styleVariant }) => {
    const labelColorKey = accordionThemeColors[$styleVariant].label;
    const labelColor = theme.colors[labelColorKey];

    return css`
      font-family: Avenir;
      font-size: 16px;
      font-weight: 800;
      color: ${labelColor};
    `;
  }}
`;

export const SubText = styled(Text)`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.textSystemInfoSecondary2100};
`;
