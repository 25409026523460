import { lightThemeColors as colors } from '../colors/themeColors';
import { TModals } from './TModals';

export const lightThemeModals: TModals = {
  variants: {
    primary: {
      closeIcon: {
        top: '19px',
        right: '19px',
      },

      innerModalContainer: {
        padding: '40px 48px',
      },

      header: {
        color: colors.textInverseWhite,
        fontSize: '20px',
        lineHeight: '28px',
        backgroundColor: colors.backgroundAccentBlueStrongestSecondary1100,
        justifyContent: 'center',
        padding: '12px 25px',
        paddingWithCloseIcon: '12px 50px 12px 25px',
      },

      content: {
        padding: '24px 24px 0',
      },

      fadeEffect: {
        padding: '0 24px',
      },
    },

    secondary: {
      closeIcon: {
        top: '24px',
        right: '24px',
      },

      innerModalContainer: {
        padding: '40px 48px',
      },

      header: {
        color: colors.textPrimarySecondary1100,
        fontSize: '24px',
        lineHeight: '36px',
        backgroundColor: colors.backgroundPrimaryWhite,
        justifyContent: 'flex-start',
        padding: '24px 32px',
        paddingWithCloseIcon: '32px 32px 0',
      },

      content: {
        padding: '16px 32px 0',
      },

      fadeEffect: {
        padding: '0 32px',
      },
    },

    tertiary: {
      closeIcon: {
        top: '24px',
        right: '24px',
      },

      innerModalContainer: {
        padding: '40px 48px',
      },

      header: {
        color: colors.textPrimarySecondary1100,
        fontSize: '24px',
        lineHeight: '36px',
        backgroundColor: colors.backgroundPrimaryWhite,
        justifyContent: 'flex-start',
        padding: '24px 32px',
        paddingWithCloseIcon: '32px 32px 16px',
      },

      content: {
        padding: '0 32px',
      },

      fadeEffect: {
        padding: '0 32px',
      },
    },
  },
};

export default lightThemeModals;
