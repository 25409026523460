import React, { useEffect, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import appstore from 'static/appstore.svg';
import { LINKS } from 'shared/links';
import { Button } from '../Button/Button';
import { XIcon } from '../icons';
import {
  BannerIconButton,
  BannerTextWrapper,
  MobileAppBannerContent,
  MobileAppBannerStyled,
} from './MobileAppBanner.styles';

export function MobileAppBanner() {
  const [bannerVisible, setBannerVisible] = useState(false);
  const showBannerKey = 'showBanner';

  const theme = useTheme();

  // There's a larger set of Android devices than iOS devices, so default to Android
  const downloadLink = isIOS
    ? LINKS.MOBILE_APP_STOREFRONTS.apple
    : LINKS.MOBILE_APP_STOREFRONTS.google;

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const noBanner = queryParams.get('no_banner');

  useEffect(() => {
    if (noBanner === 'true') {
      setBannerVisible(false);
    } else {
      const savedBannerVisibleRaw = localStorage.getItem(showBannerKey);
      const savedBannerVisible = JSON.parse(
        savedBannerVisibleRaw === null
          ? JSON.stringify(true)
          : JSON.stringify(savedBannerVisibleRaw),
      );
      setBannerVisible(savedBannerVisible);
    }
  }, [noBanner]);

  const dismissBannerHandler = () => {
    setBannerVisible(false);
    localStorage.setItem(showBannerKey, JSON.stringify(false));
  };

  const handleClick = () => {
    window.location.href = downloadLink;
  };

  return bannerVisible && isMobile ? (
    <MobileAppBannerStyled>
      <MobileAppBannerContent>
        <BannerIconButton onClick={dismissBannerHandler}>
          <XIcon color={theme.colors.iconIconSecondarySubtleGrey80} />
        </BannerIconButton>

        <img src={appstore} alt="everbright mobile app icon" />
        <BannerTextWrapper>
          <b>MyEverBright</b>
          <p>Download our app for a more seamless experience.</p>
        </BannerTextWrapper>

        <Button label="Install" styleVariant="secondary" onClick={handleClick} />
      </MobileAppBannerContent>
    </MobileAppBannerStyled>
  ) : null;
}

export default MobileAppBanner;
