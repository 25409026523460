import styled, { css } from 'styled-components';
import {
  getDropdownStyleVariantValues,
  TDropdownStatesKey,
  TDropdownStyleVariantsKey,
} from 'shared/design-system/theme/dropdowns';

export type TDropdownTargetPosition = { top: number; left: number; height: number; width: number };

export type TDropdownContainerProps = {
  $isOpen: boolean;
  $isFullWidth: boolean;
  $isInPortal?: boolean;
  $styleVariant: TDropdownStyleVariantsKey;
  $state: TDropdownStatesKey;
  $target?: TDropdownTargetPosition;
};

export const DropdownContainer = styled.ul<TDropdownContainerProps>`
  ${({ $isOpen = false, $styleVariant, $state, $target, $isFullWidth, $isInPortal, theme }) => {
    const { background, border, borderRadius, padding } = getDropdownStyleVariantValues(
      theme,
      $styleVariant,
      $state,
    );

    const top = $target ? `${$target.top + window.scrollY + $target.height}px` : '';
    const left = $target ? `${$target.left + window.scrollX}px` : '';
    const width = $target ? `${$target.width}px` : '';

    const otherStyles = $isInPortal
      ? css`
          top: ${top};
          left: ${left};
          width: ${width};
        `
      : css`
          width: ${$isFullWidth ? '100%' : 'fit-content'};
        `;

    return css`
      position: absolute;
      z-index: 999;
      display: ${$isOpen ? 'block' : 'none'};
      max-height: 150px;
      overflow: auto;
      background: ${background};
      border: ${border};
      border-radius: ${borderRadius};
      padding: ${padding};
      margin: 0;
      box-shadow:
        0px 4px 8px -2px rgba(34, 52, 91, 0.1),
        0px 2px 4px -2px rgba(34, 52, 91, 0.06);

      ${otherStyles}
    `;
  }}
`;
