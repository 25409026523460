import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'shared/components/Modal/Modal';
import { CtaButtons } from 'shared/components/Modal/types';
import BillingFormModalBody, {
  IFormInput,
  NEW_BILLING_FORM_MODAL_ID,
} from 'admin/modules/agreements/components/BillingFormModalBody/BillingFormModalBody';
import { useTheme } from 'styled-components';
import { AngleIcon } from 'shared/components/icons/AngleIcon';

type Props = {
  openBillingFormModal: boolean;
  handleCloseBillingForm: () => void;
  handleBackBillingForm: () => void;
  handleSubmitBillingForm: (formData: IFormInput) => void;
  frontDropDown?: boolean;
};

export function BillingAddressFormModal({
  openBillingFormModal,
  handleCloseBillingForm,
  handleBackBillingForm,
  handleSubmitBillingForm,
  frontDropDown,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const backButtonIcon = () => <AngleIcon color={theme.colors.buttonButtonPrimarySecondary2100} />;
  const body = (
    <BillingFormModalBody
      onSubmit={(formData) => {
        handleSubmitBillingForm(formData);
      }}
      placeholderEmpty
      marginTop
      borderRadius
      frontDropDown={frontDropDown}
    />
  );
  const ctaButtons: CtaButtons = {
    secondary: {
      label: t('cta.back'),
      onClick: handleBackBillingForm,
      Icon: backButtonIcon,
      margin: '0px 196px 0px 0px',
      iconCenterAlign: true,
      iconBorderAlign: true,
    },
    primary: {
      formId: NEW_BILLING_FORM_MODAL_ID,
      type: 'submit',
      label: t('cta.next'),
      margin: '0px 10px 0px 0px',
    },
  };

  return (
    <Modal
      isOpen={openBillingFormModal}
      fitContent
      isFullHeight
      noCtaPadding
      styleVariant="tertiary"
      contentLabel="Modal"
      ctaButtons={ctaButtons}
      title={t('confirmBillingAddress.form')}
      onRequestClose={handleCloseBillingForm}
    >
      {body}
    </Modal>
  );
}

export default BillingAddressFormModal;
