import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import pages from 'pages';
import { NoMatch } from 'shared/components/NoMatch/NoMatch';
import { Agreements } from 'admin/modules/agreements/screens/Agreements';
import { Admins } from 'admin/modules/admins/screens/Admins';
import { AuthCopyAck } from 'admin/modules/auth-copy-ack/screens/AuthCopyAck';
import { ManageFaqs } from 'admin/modules/manage-faqs/screens/ManageFaqs';
import { Statistics } from 'admin/modules/statistics/screens/Statistics';
import { Backfill } from 'admin/modules/backfill/screens/Backfill';
import { UserProfile } from 'admin/modules/user-profile/screens/UserProfile';

import { AdminLayout as Layout } from './admin-layout';
import { SupportGuard } from '../../guards/SupportGuard';

export const Admin: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        path: `${pages.ADMIN}/*`,
        children: [
          {
            index: true,
            element: <Navigate to={`/${pages.ADMIN}/${pages.AGREEMENTS}`} replace />,
          },

          {
            path: pages.USER,
            element: (
              <SupportGuard>
                <UserProfile />
              </SupportGuard>
            ),
          },
          { path: pages.AGREEMENTS, element: <Agreements /> },
          { path: pages.ADMINS, element: <Admins /> },
          { path: pages.STATISTICS, element: <Statistics /> },
          { path: pages.MANAGE_FAQS, element: <ManageFaqs /> },
          { path: pages.AUTH_COPY_ACK, element: <AuthCopyAck /> },
          { path: pages.BACKFILL, element: <Backfill /> },
        ],
        errorElement: <NoMatch />,
      },
    ],
  },
];

export default Admin;
