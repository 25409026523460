import { TTheme } from 'shared/design-system/theme/theme';
import {
  DISABLED_DROPDOWN_STATE,
  ENABLED_DROPDOWN_STATE,
  ERROR_DROPDOWN_STATE,
  TDropdownStatesKey,
  TDropdownStyleVariantsKey,
} from './TDropdown';

// STATE VALUE
export const getDropdownState = (isDisabled: boolean, showErrorState?: boolean) => {
  if (isDisabled) {
    return DISABLED_DROPDOWN_STATE;
  }

  return showErrorState ? ERROR_DROPDOWN_STATE : ENABLED_DROPDOWN_STATE;
};

// RETURNS ALL STYLES RELATED TO DROPDOWN STYLE VARIANT
export const getDropdownStyleVariantValues = (
  theme: TTheme,
  variant: TDropdownStyleVariantsKey,
  state: TDropdownStatesKey,
) => ({
  ...theme.dropdowns.variants[variant][state],
  ...theme.dropdowns.variants[variant],
});
