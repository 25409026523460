import pages, { PageKey } from 'pages';
import { useMemo } from 'react';
import { resolvePath, useLocation } from 'react-router';

/** Returns a name corresponding to the screen/page currently displayed to the user */
export function usePageName() {
  const { pathname } = useLocation();

  const currentPage: PageKey | 'AUTH' | undefined = useMemo(() => {
    const authPages = [
      resolvePath(pages.LOGIN).pathname,
      resolvePath(pages.FORGOT_PASSWORD).pathname,
      resolvePath(pages.CHECK_EMAIL).pathname,
      resolvePath(pages.REGISTRATION).pathname,
      resolvePath(pages.VERIFICATION).pathname,
      resolvePath(pages.PASSWORD).pathname,
      resolvePath(pages.TOO_MANY_ATTEMPTS).pathname,
    ];

    if (authPages.includes(pathname)) {
      return 'AUTH';
    }

    if (
      resolvePath(pages.OVERVIEW, `/${pages.DASHBOARD}`).pathname === pathname ||
      resolvePath(`/${pages.DASHBOARD}`).pathname === pathname ||
      pathname.startsWith(`/${pages.DASHBOARD}/${pages.AGREEMENTS}`) ||
      resolvePath(`/${pages.STATUS}`).pathname === pathname
    ) {
      return 'OVERVIEW';
    }

    if (
      resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`).pathname === pathname ||
      resolvePath(pages.HARDWARE_INFO, `/${pages.DASHBOARD}/${pages.SYSTEM}`).pathname === pathname
    ) {
      return 'SYSTEM';
    }

    if (resolvePath(pages.BILLING, `/${pages.DASHBOARD}`).pathname === pathname) return 'BILLING';

    if (
      resolvePath(pages.SUPPORT, `/${pages.DASHBOARD}`).pathname === pathname ||
      resolvePath(`/${pages.SUPPORT}`).pathname === pathname
    ) {
      return 'SUPPORT';
    }

    if (
      resolvePath(pages.AGREEMENTS, `/${pages.ADMIN}`).pathname === pathname ||
      pathname.startsWith(`/${pages.ADMIN}/${pages.USER}`)
    ) {
      return 'AGREEMENTS';
    }

    if (resolvePath(pages.ADMINS, `/${pages.ADMIN}`).pathname === pathname) {
      return 'ADMINS';
    }

    if (resolvePath(pages.STATISTICS, `/${pages.ADMIN}`).pathname === pathname) {
      return 'STATISTICS';
    }

    if (resolvePath(pages.MANAGE_FAQS, `/${pages.ADMIN}`).pathname === pathname) {
      return 'MANAGE_FAQS';
    }

    if (resolvePath(pages.AUTH_COPY_ACK, `/${pages.ADMIN}`).pathname === pathname) {
      return 'AUTH_COPY_ACK';
    }

    if (resolvePath(pages.BACKFILL, `/${pages.ADMIN}`).pathname === pathname) {
      return 'BACKFILL';
    }

    return undefined;
  }, [pathname]);

  return { currentPage };
}

export default usePageName;
