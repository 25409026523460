import {
  TDropdownStyleVariantsKey,
  TDropdownStatesKey,
} from 'shared/design-system/theme/dropdowns';
import styled from 'styled-components';
import { DropdownContainer } from '../Dropdown/Dropdown.styles';

export const CustomSelectContainer = styled.div`
  cursor: pointer;
  position: relative;
  align-self: flex-end;

  ${DropdownContainer} {
    width: fit-content;
    right: 0;
    border: 1px solid ${({ theme }) => theme.colors.strokeSecondaryGrey10};
    margin-top: 9px;
    h5 {
      text-wrap: nowrap;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      position: fixed;
      width: 100%;
      border: 0;
      border-top: 1px solid ${({ theme }) => theme.colors.backgroundInteractionHoverGrey10};
      border-radius: 0;
      margin-top: 0;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    align-self: center;
  }
`;

export const NativeSelect = styled.select`
  display: none;
`;

type SelectSelectedProps = {
  $styleVariant: TDropdownStyleVariantsKey;
  $state: TDropdownStatesKey;
};

export const SelectSelected = styled.div<SelectSelectedProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;

  &:focus {
    outline: none;
    z-index: 1;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-bottom: 0;
  }
`;

export const DropdownWrapper = styled.div`
  overflow: hidden;
`;

export const Scrim = styled.div<{ $show: boolean }>`
  position: fixed;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgroundOpacityOpacityScrimGrey8075};
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  z-index: 10;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.small}) {
    display: none;
  }
`;
