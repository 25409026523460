import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

import useUser from 'hooks/useUser';

import { getDashboardRedirectPath } from 'modules/auth/Auth.utils';
import { LoadingLogo } from 'shared/components/Modal/LoadingLogo';

export function DashboardGuard() {
  const { userResult, selectedAccount } = useUser();
  const location = useLocation();

  if (userResult?.loading) return <LoadingLogo />;

  const { hasDashboardAccess, redirectPath } = getDashboardRedirectPath(
    userResult?.user || undefined,
    selectedAccount,
  );

  if (!hasDashboardAccess) {
    return <Navigate to={redirectPath} replace state={{ path: location.pathname }} />;
  }

  return <Outlet />;
}

export default DashboardGuard;
