import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FaqType } from 'api/types';
import {
  useAddFaq,
  useAddTag,
  useCreateTags,
  useEditFaq,
  useGetFAQs,
  useGetTags,
  useRemoveTag,
} from 'api/faqs';
import { Header } from 'shared/components/Header/Header';
import { Button } from 'shared/components/Button/Button';
import { Plus } from 'shared/components/icons';
import { FaqsWapper } from './ManageFaqs.styles';
import { FaqCard } from '../components/FaqCard/FaqCard';
import { AddTagModal, AddTagsProp } from '../components/AddTagModal/AddTagModal';
import {
  FaqFormModal,
  IFormInput as FaqFormInputType,
} from '../components/FaqFormModal/FaqFormModal';

enum Modal {
  ADD_TAG = 'ADD_TAG',
  FAQ_FORM = 'FAQ_FORM',
}

export function ManageFaqs() {
  const [modalOpen, setModalOpen] = useState<Modal | null>(null);
  const [faqSelected, setFaqSelected] = useState<FaqType>();

  const { data: faqs, refetch: refetchFAQs } = useGetFAQs();
  const { data: tags, refetch: refetchTags } = useGetTags();

  const orderedFaqs = faqs?.sort((a, b) => a.order - b.order);

  const { t } = useTranslation('translation', { keyPrefix: 'admin.faq' });
  const { t: tGeneral } = useTranslation();

  const closeModal = () => {
    setFaqSelected(undefined);
    setModalOpen(null);
  };

  const { mutate: addFaq } = useAddFaq({
    onSuccess: () => {
      refetchFAQs();
      closeModal();
    },
  });

  const { mutate: editFaq } = useEditFaq({
    onSuccess: () => {
      refetchFAQs();
      closeModal();
    },
  });

  const { mutate: addTag } = useAddTag({
    onSuccess: () => {
      refetchFAQs();
      closeModal();
    },
  });

  const { mutate: createTags } = useCreateTags({
    onSuccess: (createdTags) => {
      if (faqSelected) {
        createdTags.forEach((tag) => {
          addTag({ faqId: faqSelected?.id, tagId: tag.id });
        });
      }
    },
  });

  const { mutate: removeTag } = useRemoveTag({
    onSuccess: () => {
      refetchFAQs();
      refetchTags();
    },
  });

  const handleEditFaq = (faq: FaqType) => {
    setFaqSelected(faq);
    setModalOpen(Modal.FAQ_FORM);
  };

  const handleAddTag = (faq: FaqType) => {
    setFaqSelected(faq);
    setModalOpen(Modal.ADD_TAG);
  };

  const openFaqForm = () => {
    setModalOpen(Modal.FAQ_FORM);
  };

  const handleAddTags = async ({ faq, newExistingTags, newTags }: AddTagsProp) => {
    createTags({ tags: newTags });

    newExistingTags.forEach((tag) => {
      addTag({ faqId: faq.id, tagId: tag.id });
    });

    refetchFAQs();
    setFaqSelected(undefined);
  };

  const handleSubmitFaqForm = (formData: FaqFormInputType, id?: string) => {
    if (id) {
      editFaq({ id, body: formData });
    } else {
      addFaq(formData);
    }
  };

  const handleCloseModal = () => {
    setFaqSelected(undefined);
    setModalOpen(null);
  };

  return (
    <>
      <FaqsWapper>
        <Header pageName="MANAGE_FAQS" title={tGeneral('admin.faqs')} />
        <div>
          <Button label={t('addFaq')} Icon={Plus} onClick={openFaqForm} />
        </div>

        {orderedFaqs?.map((faq, idx) => (
          <FaqCard
            key={faq.id}
            label={t('questionNumber', { number: idx + 1 })}
            faq={faq}
            onClickAddTag={() => handleAddTag(faq)}
            onClickEditFaq={() => handleEditFaq(faq)}
            onClickRemoveTag={removeTag}
          />
        ))}
      </FaqsWapper>
      {faqSelected && (
        <AddTagModal
          isOpen={modalOpen === Modal.ADD_TAG}
          faq={faqSelected}
          allTags={tags || []}
          onClickAddTags={handleAddTags}
          onClickRemoveTag={removeTag}
          onClose={handleCloseModal}
        />
      )}

      <FaqFormModal
        isOpen={modalOpen === Modal.FAQ_FORM}
        faq={faqSelected}
        currentFaqLength={faqs?.length || 0}
        onSubmitFaqForm={handleSubmitFaqForm}
        onClose={handleCloseModal}
      />
    </>
  );
}

export default ManageFaqs;
