import React from 'react';
import { createPortal } from 'react-dom';
import { useOutsideClick } from 'hooks/useOutsideClick';
import {
  DEFAULT_DROPDOWN_STYLE_VARIANT,
  ENABLED_DROPDOWN_STATE,
  TDropdownStyleVariantsKey,
} from 'shared/design-system/theme/dropdowns';
import { DropdownContainer, TDropdownTargetPosition } from './Dropdown.styles';

export type TDropdownProps = {
  id?: string;
  ariaLabelledBy?: string;
  role?: string;
  isOpen?: boolean;
  isFullWidth?: boolean;
  target?: TDropdownTargetPosition;
  styleVariant?: TDropdownStyleVariantsKey;
  children: React.ReactNode;
  useCreatePortal?: boolean;
  onClick?: (e: React.MouseEvent<HTMLUListElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLUListElement>) => void;
  onClickOutside?: () => void;
};

/**
 * @param {string} [id] - id (optional)
 * @param {boolean} [isOpen] - is dropdown open (optional)
 * @param {string} [ariaLabelledBy] - aria-labelledby (optional)
 * @param {string} [role] - role of dropdown container. defaults to listbox (optional)
 * @param {boolean} [isFullWidth] -  is dropdown full width of container. defaults to false (optional)
 * @param {TDropdownStyleVariantsKey} [styleVariant] - (optional)
 * @param {React.ReactNode} [children] - children
 * @param {function} [onClick] - click handler (optional)
 * @param {function} [onBlur] - blur handler (optional)
 * @param {function} [onClickOutside] - handler for clicking outside of the dropdown
 */

export function Dropdown({
  id,
  ariaLabelledBy,
  isOpen = false,
  isFullWidth = false,
  role = 'listbox',
  target,
  styleVariant = DEFAULT_DROPDOWN_STYLE_VARIANT,
  children,
  useCreatePortal = false,
  onClick,
  onBlur,
  onClickOutside,
}: TDropdownProps) {
  const ref = useOutsideClick(onClickOutside, isOpen) as React.RefObject<HTMLUListElement>;

  return (
    <div>
      {useCreatePortal ? (
        createPortal(
          <DropdownContainer
            ref={ref}
            id={id}
            aria-labelledby={ariaLabelledBy}
            role={role}
            $isOpen={isOpen}
            $isFullWidth={isFullWidth}
            $styleVariant={styleVariant}
            $state={ENABLED_DROPDOWN_STATE}
            $target={target}
            $isInPortal
            onClick={onClick}
            onBlur={onBlur}
          >
            {isOpen ? children : null}
          </DropdownContainer>,
          document.body,
        )
      ) : (
        <DropdownContainer
          ref={ref}
          id={id}
          aria-labelledby={ariaLabelledBy}
          role={role}
          $isOpen={isOpen}
          $isFullWidth={isFullWidth}
          $styleVariant={styleVariant}
          $state={ENABLED_DROPDOWN_STATE}
          $target={target}
          onClick={onClick}
          onBlur={onBlur}
        >
          {isOpen ? children : null}
        </DropdownContainer>
      )}
    </div>
  );
}

export default Dropdown;
