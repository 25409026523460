import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

export function AuthCopyAckIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.77778 13.8889H8.63056C8.93611 14.2889 9.3 14.6694 9.72778 15.0111C9.47778 15.1444 9.19167 15.2222 8.88889 15.2222H1.77778C0.797222 15.2222 0 14.425 0 13.4444V2.77778C0 1.79722 0.797222 1 1.77778 1H6.375C6.84722 1 7.3 1.18611 7.63333 1.51944L10.1472 4.03333C10.4806 4.36667 10.6667 4.81944 10.6667 5.29167V6.75L9.33333 7.28333V5.44444H7.11111C6.61944 5.44444 6.22222 5.04722 6.22222 4.55556V2.33333H1.77778C1.53333 2.33333 1.33333 2.53333 1.33333 2.77778V13.4444C1.33333 13.6889 1.53333 13.8889 1.77778 13.8889ZM11.7528 7.26944C11.9111 7.20556 12.0889 7.20556 12.2472 7.26944L15.5806 8.60278C15.8333 8.70556 16 8.95 16 9.22222C16 10.9806 15.2806 13.9111 12.2556 15.1722C12.0917 15.2417 11.9056 15.2417 11.7417 15.1722C8.71945 13.9111 8 10.9806 8 9.22222C8 8.95 8.16667 8.70556 8.41945 8.60278L11.7528 7.26944ZM14.65 9.66667L12 8.60556V13.825C13.8944 12.9083 14.5417 11.075 14.65 9.66667Z"
        fill={color}
      />
    </svg>
  );
}

export default AuthCopyAckIcon;
