export const LINKS = {
  EVERBRIGHT_OMNIDIAN_LOGIN: 'https://everbright.omnidian.com/#/login',
  EVERBRIGHT_OMNIDIAN_SERVICE_REQUEST: 'https://www.omnidian.com/service-request',
  MOBILE_APP_STOREFRONTS: {
    google: 'https://play.google.com/store/apps/details?id=com.goeverbright.MyEverBright',
    apple: 'https://apps.apple.com/us/app/myeverbright/id1641960636',
  },
  MYEVERBRIGHT_PARTNER_LOGIN: 'https://engine.goeverbright.com/auth/login?redirect=myeverbright',
  FOOTER_MENU_OPTIONS: [
    { text: 'Privacy Policy', link: 'https://www.goeverbright.com/privacy-policy' },
    {
      text: 'California Residents Notice at Collection',
      link: 'https://www.goeverbright.com/privacy-policy?hsLang=en#California',
    },
    { text: 'Terms & Conditions', link: 'https://www.goeverbright.com/terms-and-conditions' },
    { text: 'Contact', link: 'https://www.goeverbright.com/contact' },
  ],
  PRIVACY_POLICY: 'https://www.goeverbright.com/privacy-policy',
};

export default LINKS;
