import React from 'react';
import {
  VictoryAxis,
  VictoryArea,
  VictoryChart,
  VictoryLabel,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import { format } from 'date-fns';
import { useTheme } from 'styled-components';

import { ToolTip } from 'modules/system/components/Charts/Tooltip/ToolTip';
import { getChartStyles } from 'modules/system/components/Charts/getChartStyles';
import { useBreakpoints } from 'hooks/useWindowSize';
import { DateRangeZoomLevel, SeriesData } from 'api/types';

type Props = {
  invitedSeriesData: SeriesData[];
  claimedSeriesData: SeriesData[];
  claimedOffset: number;
};

const getCumulativeData = (seriesData: SeriesData[], offset = 0) => {
  for (let i = 0; i + 1 < seriesData.length; i += 1) {
    const date = new Date(seriesData[i].date);
    const nextDate = new Date(seriesData[i + 1].date);
    date.setDate(date.getDate() + 1);

    if (
      format(date.toISOString(), 'MMM d, yyyy') !== format(nextDate.toISOString(), 'MMM d, yyyy')
    ) {
      const datum = { count: 0, date: date.toISOString() };
      seriesData.splice(i + 1, 0, datum);
    }
  }

  let i = offset;
  return seriesData.map((datum) => {
    const date = new Date(datum.date);
    i += datum.count;
    return {
      x: date,
      y: i,
      customTooltip: { date: format(date, 'M/d/yy'), value: `Invites: ${i}` },
    };
  });
};

export function AccountChart({ invitedSeriesData, claimedSeriesData, claimedOffset }: Props) {
  const theme = useTheme();
  const screenSize = useBreakpoints();

  const chartStyles = getChartStyles(theme, screenSize, DateRangeZoomLevel.WEEK);

  return (
    <VictoryChart
      width={chartStyles.width}
      padding={{ top: 50, bottom: 50, left: 80, right: 50 }}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={() => ' '}
          labelComponent={<VictoryTooltip flyoutComponent={<ToolTip chartType="area" />} />}
        />
      }
    >
      <VictoryAxis dependentAxis style={{ tickLabels: chartStyles.tickLabels }} />
      <VictoryAxis
        tickFormat={(date) => format(date, 'M/yy')}
        style={{ tickLabels: chartStyles.tickLabels }}
      />
      <VictoryArea
        style={{ data: { fill: theme.colors.chartInteractionDarkBlue } }}
        data={getCumulativeData(invitedSeriesData)}
      />
      <VictoryArea
        style={{ data: { fill: theme.colors.chartChartBrandGreenPrimary2100 } }}
        data={getCumulativeData(claimedSeriesData, claimedOffset)}
      />
      <VictoryLabel
        dx={chartStyles.label.x}
        y={chartStyles.label.y}
        style={chartStyles.label.style}
      />
    </VictoryChart>
  );
}

export default AccountChart;
