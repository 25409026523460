import React from 'react';

import { StatsCountType } from 'api/types';
import Table from 'shared/components/Table/Table';
import TableCellString from 'shared/components/Table/TableCell/TableCellString/TableCellString';

type Props = {
  name: string;
  fields: StatsCountType[];
};

export function FieldCountTable({ name, fields }: Props) {
  const columns = [{ title: name }, { title: 'Amount' }];

  const rows = fields.map((field, id) => ({
    key: `table-${name}-${id}`,
    cells: [
      <TableCellString text={field.value ? field.value : 'None'} />,
      <TableCellString text={field.count.toString()} />,
    ],
  }));

  return <Table columns={columns} rows={rows} />;
}

export default FieldCountTable;
