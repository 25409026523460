import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PrimaryText = styled.h1`
  font-style: normal;
  font-weight: 900;
  font-size: 34px;
  color: #22345b;
`;

export const BodyWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 600px;
  margin-top: 32px;
`;

export const BodyText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
`;

export const SVGImage = styled.img`
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    max-width: 600px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xLarge}) {
    width: 900px;
    max-width: 100%;
  }
`;

export const LinkWrapper = styled.div`
  margin: 0 auto 0 0;
`;
