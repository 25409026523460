import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useBreakpoints } from 'hooks/useWindowSize';
import { Modal } from 'shared/components/Modal/Modal';
import { AddressType } from 'api/types';
import LocationIcon from 'shared/components/icons/LocationIcon';
import { CtaButtons } from 'shared/components/Modal/types';
import {
  BillingAddressModalContent,
  HeaderDiv,
  LocationDiv,
  StyledLocation,
  StyledLocationBottom,
  StyledText,
} from './BillingAddressModal.styles';

type BillingAddressType = Omit<AddressType, 'id' | 'archived'>;

export type BillingAddressModalProps = {
  isOpen: boolean;
  address: BillingAddressType | undefined;
  onChangeConfirmed: () => void;
  onOpenBillingForm: () => void;
  onCloseBillingAddressModal: () => void;
  submittedForm: boolean;
};

export function BillingAddressModal({
  isOpen,
  address,
  onChangeConfirmed,
  onOpenBillingForm,
  onCloseBillingAddressModal,
  submittedForm,
}: BillingAddressModalProps) {
  const { t } = useTranslation();
  const screenSize = useBreakpoints();
  const addressLine1 = address?.street_1;
  const addressLine2 = `${address?.city}, ${address?.state} ${address?.zip_code}`;
  const ctaButtons = () => {
    const buttons: CtaButtons = {
      tertiary: {
        label: t('cta.update'),
        onClick: onOpenBillingForm,
      },
      primary: {
        label: t('cta.confirm'),
        onClick: onChangeConfirmed,
      },
    };
    return buttons;
  };
  return (
    <Modal
      contentLabel={t('autopayModal.setUpAutopay')}
      title={t('confirmBillingAddress.modalTitle')}
      styleVariant="tertiary"
      isOpen={isOpen}
      isFullWidth={screenSize === 'sm'}
      fitContent
      padding="32px"
      ctaButtons={ctaButtons()}
      onRequestClose={onCloseBillingAddressModal}
    >
      <BillingAddressModalContent>
        <HeaderDiv>
          <Trans>
            <StyledText>
              {submittedForm
                ? t('confirmBillingAddress.billingQuestion', { bills: 'first bill' })
                : t('confirmBillingAddress.billingQuestion', { bills: 'bills' })}
            </StyledText>
          </Trans>
        </HeaderDiv>
        <LocationDiv>
          <LocationIcon />
          <StyledLocation>{addressLine1}</StyledLocation>
        </LocationDiv>
        <StyledLocationBottom>{addressLine2}</StyledLocationBottom>
      </BillingAddressModalContent>
    </Modal>
  );
}

export default BillingAddressModal;
