import React, { Context, createContext, useMemo, useState } from 'react';
import { FaqType } from 'api/types';

export type NotificationsType = ReadonlyArray<NotificationType>;

export interface NotificationType {
  severity: 'error' | 'success' | 'warning';
  text: string;
}

export interface StateContextType {
  faqs: FaqType[];
  setFaqs: (faqs: FaqType[]) => void;
  notifications: NotificationType[];
  setNotifications: (notifications: NotificationType[]) => void;
}

const StateContext: Context<StateContextType> = createContext({} as StateContextType);

interface Props {
  children: React.ReactNode;
}

function StateContextProvider({ children }: Props) {
  const [faqs, setFaqs] = useState<FaqType[]>([]);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  const value = useMemo(
    () => ({
      faqs,
      setFaqs,
      notifications,
      setNotifications,
    }),
    [faqs, notifications],
  );

  return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
}

export { StateContext, StateContextProvider };
